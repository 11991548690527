@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;500;700&family=Open+Sans:wght@300;500&display=swap');

body {
  background: #14213D;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
}

.content {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

a {
  color: white;
}

h1 {
  color: #FCA311;
  font-family: 'Montserrat', sans-serif;
  font-size: 65px;
  margin-bottom: 10px;
}

@keyframes blink {
  0%, 100% { opacity: 0; }
  50% { opacity: 1; }
}

.dot {
  animation: blink 1s infinite;
}
